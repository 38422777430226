import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService"; // Washer icon
import TvIcon from "@mui/icons-material/Tv"; // TV icon
import AcUnitIcon from "@mui/icons-material/AcUnit"; // Central air conditioning
import SmokeFreeIcon from "@mui/icons-material/SmokeFree"; // Smoke alarm
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher"; // Fire extinguisher
import FirstAidIcon from "@mui/icons-material/HealthAndSafety"; // First aid kit
import WifiIcon from "@mui/icons-material/Wifi"; // WiFi icon
import WorkIcon from "@mui/icons-material/Work"; // Dedicated workspace
import KitchenIcon from "@mui/icons-material/Kitchen"; // Kitchen icon
import MicrowaveIcon from "@mui/icons-material/Microwave"; // Microwave icon
import ChairIcon from "@mui/icons-material/Chair"; // Outdoor furniture
import BeachAccessIcon from "@mui/icons-material/BeachAccess"; // Shared beach access
import BalconyIcon from "@mui/icons-material/Balcony"; // Patio or balcony
import LocalParkingIcon from "@mui/icons-material/LocalParking"; // Parking icon
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Long term stays icon
import LockIcon from "@mui/icons-material/Lock"; // Smart lock icon
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom"; // Private entrance unavailable
import CancelIcon from "@mui/icons-material/Cancel"; // Unavailable items (cross icon)
import BedIcon from "@mui/icons-material/Bed"; // Bed-related items
import LocalMallIcon from "@mui/icons-material/LocalMall"; // Placeholder for Essentials
import HomeIcon from '@mui/icons-material/Home'; // Default icon

const amenitiesList = [
  {
    category: "Bedroom and laundry",
    items: [
      { icon: LocalLaundryServiceIcon, label: "Washer – In unit" },
      { icon: LocalLaundryServiceIcon, label: "Dryer - In unit" },
      { icon: LocalMallIcon, label: "Essentials (Towels, bed sheets, soap, and toilet paper)" }, // Now with icon
      { icon: BedIcon, label: "Bed linens" },
      { icon: BedIcon, label: "Extra pillows and blankets" },
      { label: "Iron" }, // No specific icon
      { label: "Clothing storage" }, // No specific icon
    ],
  },
  {
    category: "Living Room",
    items: [
      { icon: TvIcon, label: "TV" },
      { label: "Sofa bed" }, // No specific icon
    ],
  },
  {
    category: "Heating and cooling",
    items: [
      { icon: AcUnitIcon, label: "Central air conditioning" },
      { label: "Ceiling fan" }, // No specific icon
      { label: "Heating" }, // No specific icon
    ],
  },
  {
    category: "Home safety",
    items: [
      { label: "Exterior security cameras on property (Ring Doorbell and HOA exterior cameras in pool area/common area)" }, // No icon
      { icon: SmokeFreeIcon, label: "Smoke alarm" },
      { icon: FireExtinguisherIcon, label: "Fire extinguisher" },
      { icon: FirstAidIcon, label: "First aid kit" },
    ],
  },
  {
    category: "Internet and office",
    items: [
      { icon: WifiIcon, label: "WiFi" },
      { icon: WorkIcon, label: "Dedicated workspace" },
    ],
  },
  {
    category: "Kitchen and dining",
    items: [
      { icon: KitchenIcon, label: "Kitchen" },
      { icon: MicrowaveIcon, label: "Refrigerator" },
      { icon: MicrowaveIcon, label: "Microwave" },
      { icon: KitchenIcon, label: "Dishwasher" },
      { label: "Stove" },
      { label: "Oven" }, // No specific icon
      { label: "Blender" },
      { label: "Coffee maker" }, // No specific icon
    ],
  },
  {
    category: "Location features",
    items: [{ icon: BeachAccessIcon, label: "Shared beach access" }],
  },
  {
    category: "Outdoor",
    items: [
      { icon: BalconyIcon, label: "Patio or balcony" },
      { icon: ChairIcon, label: "Outdoor furniture" },
    ],
  },
  {
    category: "Parking and facilities",
    items: [
      { icon: LocalParkingIcon, label: "Free parking on premises – 1 space" },
      { icon: LocalParkingIcon, label: "Additional guest parking available" },
      { icon: ChairIcon, label: "Shared outdoor pool" },
    ],
  },
  {
    category: "Services",
    items: [
      { icon: CheckCircleIcon, label: "Long term stays allowed (Allow stay for 28 days or more)" },
      { icon: LockIcon, label: "Self check-in" },
      { icon: LockIcon, label: "Smart lock" },
    ],
  },
  // {
  //   category: "Not included",
  //   items: [
  //     { icon: CancelIcon, label: "Unavailable: Carbon monoxide alarm (There is no carbon monoxide detector on the property)" },
  //     { icon: CancelIcon, label: "Unavailable: Shampoo" },
  //     { icon: NoMeetingRoomIcon, label: "Unavailable: Private entrance" },
  //   ],
  // },
];

const AmenitiesDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        What this place offers
        <IconButton
          onClick={handleClose}
          style={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent  className="custom-scrollbar">
        <Box sx={{ mb: 4 }}>
          {amenitiesList.map((section) => (
            <div key={section.category}>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ mt: 2, mb: 1 }}
              >
                {section.category}
              </Typography>
              {section.items.map((item, index) => (
                <Box display="flex" alignItems="center" mb={1} key={index}>
                  {/* Use the provided icon or fall back to the default HomeIcon */}
                  {item.icon ? <item.icon sx={{ mr: 1 }} /> : <HomeIcon sx={{ mr: 1 }} />}
                  <Typography>{item.label}</Typography>
                </Box>
              ))}
            </div>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AmenitiesDialog;
