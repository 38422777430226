import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axiosApiInstance from '../../store/axiosApi';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RefundRequestPage = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Helper function to validate email
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleRefundRequest = async () => {
    // Validation logic
    if (!firstName) {
      showErrorToast('First Name is required');
      return;
    }
    if (!lastName) {
      showErrorToast('Last Name is required');
      return;
    }
    if (!checkInDate) {
      showErrorToast('Check-in Date is required');
      return;
    }
    if (!email) {
      showErrorToast('Email is required');
      return;
    }
    if (!validateEmail(email)) {
      showErrorToast('Please enter a valid email');
      return;
    }
    if (!phone) {
      showErrorToast('Phone number is required');
      return;
    }

    try {
      const response = await axiosApiInstance.post('/refund_purely_palm', {
        email,
        phone,
        firstName,
        lastName,
        checkInDate,
      });

      setMessage('Refund request submitted successfully.');
      showSuccessToast('Refund request submitted successfully.');
    } catch (error) {
      setMessage(error.response?.data?.message || "Something went wrong.");
      showErrorToast(error.response?.data?.message || "Something went wrong.");
    }
  };

  const showSuccessToast = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000, // Auto close after 5 seconds
      closeOnClick: true, // Close when clicked
      pauseOnHover: true, // Pause auto close when hovered
      draggable: true, // Enable draggable toast
      hideProgressBar: false, // Show progress bar
    });
  };
  // Error Toast
  const showErrorToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000, // Auto close after 5 seconds
      closeOnClick: true, // Close when clicked
      pauseOnHover: true, // Pause auto close when hovered
      draggable: true, // Enable draggable toast
      hideProgressBar: false, // Show progress bar
    });
  };

  return (
    <Box className="p-2" sx={{ mt: 1, mx: 'auto', maxWidth: '500px' }}>
      <ToastContainer />
      <Typography variant="h4" component="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        <ArrowBackIosNewIcon
          onClick={() => navigate("/rentals/purely_palms/calendar")}
          className="mr-2 cursor-pointer"
        />
        Request a Refund
      </Typography>
      <Typography className="mt-4" variant="body1" gutterBottom>
        You can get a full refund if requested 30 or more days before your check-in date. Please enter your details below to request a refund.
      </Typography>
      <TextField
        fullWidth
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Check-in Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={checkInDate}
        onChange={(e) => setCheckInDate(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        margin="normal"
        required
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleRefundRequest}
        sx={{ mt: 2 }}
      >
        Request Refund
      </Button>
      {message && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default RefundRequestPage;
