import React, { useState, useEffect, useMemo, useRef } from "react";
import { DateRange } from "react-date-range";
import { addDays, addMonths, startOfMonth, endOfMonth } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axiosApiInstance from "../../store/axiosApi";
import AmenitiesDialog from "./AmenitiesDialog";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  IconButton,
  Divider,
  Rating,
  Tooltip,
} from "@mui/material";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";
import { ArrowForwardIos, LinkSharp, Sms } from "@material-ui/icons";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import appConfig from "../../config/config";
const stripePromise = loadStripe(appConfig.STRIPE_SK);
import { useParams } from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PeopleIcon from "@mui/icons-material/People";
import PetsIcon from "@mui/icons-material/Pets";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/Add";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import KitchenIcon from "@mui/icons-material/Kitchen";
import WifiIcon from "@mui/icons-material/Wifi";
import WorkIcon from "@mui/icons-material/Work";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import PoolIcon from "@mui/icons-material/Pool";
import TvIcon from "@mui/icons-material/Tv";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import BlockIcon from "@mui/icons-material/Block";

import InfoIcon from "@mui/icons-material/Info";
export default function BookingForm({ rental }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [totalPrice, setTotalPrice] = useState({
    totalNightPrice: 0,
    nights: 0,
    cleaningFee: 0,
    damageWaiverFee: 0,
    taxAmount: 0,
    finalTotalPrice: 0,
    totalBeforeTax: 0,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showContactDialog, setShowContentDialog] = useState(false);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [showBookingForm, setShowBookingForm] = useState(false); // New state for showing the form modal
  const [email, setEmail] = useState(""); // State for email input
  const [phone, setPhone] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pricesByDate, setPricesByDate] = useState([]);
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [showPriceHomeDetails, setShowPriceHomeDetails] = useState(false);
  const currentMonthStart = startOfMonth(new Date());
  const currentMonthEnd = endOfMonth(new Date());
  const [paymentOption, setPaymentOption] = useState("full");
  const [reviews, setReviews] = useState([]);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [showFullSizeImage, setShowFullSizeImage] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");
  const [fullSizeImageSrc, setFullSizeImageSrc] = useState("");
  const [monthlyPrices, setMonthlyPrices] = useState([]);

  const [showTooltip, setShowTooltip] = useState(false);
  const [showConfirmTooltip, setShowConfirmTooltip] = useState(false);
  const averageRating = reviews.length
    ? (
        reviews.reduce((acc, review) => acc + parseFloat(review.rating), 0) /
        reviews.length
      ).toFixed(1)
    : null;

  const isNonStandardPort =
    window.location.port && !["80", "443"].includes(window.location.port);
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    isNonStandardPort ? `:${window.location.port}` : ""
  }`;
  const { status = "" } = useParams();

  const toggleAmenities = () => setShowAmenities(!showAmenities);
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
    if (!showDatePicker) {
      setFocusedInput("startDate");
    }
  };
  const navigate = useNavigate();
  const [showAmenities, setShowAmenities] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const [guests, setGuests] = useState(1);
  const toggleBookingDialog = () => {
    setShowBookingForm(!showBookingForm);
  };
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const today = new Date();
  const checkinDate = new Date(range[0].startDate);
  const daysDifference = (checkinDate - today) / (1000 * 60 * 60 * 24);
  today.setHours(0, 0, 0, 0);
  checkinDate.setHours(0, 0, 0, 0);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [openAmetityDialog, setAmetityDialog] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [review, setReview] = useState({
    firstName: "",
    lastName: "",
    email: "",
    checkinDate: "",
    rating: 5,
    reviewText: "",
  });
  const images = [
    "/img/purely_logo.png",
    "/img/K0.jpg",
    "/img/K2.jpg",
    "/img/K3.jpg",
    "/img/K4.jpg",
    "/img/K5.jpg",
    "/img/K6.jpg",
    "/img/K7.jpg",
    "/img/K8.jpg",
    "/img/K9.jpg",
    "/img/K18.webp",
    "/img/K10.jpg",
    "/img/Beach.webp",
    "/img/Sunset.jpg",
  ];
  const tooltipRef = useRef(null);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [damageTooltipOpen, setDamageTooltipOpen] = useState(false);
  const [damageConfirmTooltipOpen, setDamageConfirmTooltipOpen] =
    useState(false);
  const handleTooltipOpen = () => {
    setDamageTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setDamageTooltipOpen(false);
  };
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);
  const handleAmentityDialogOpen = () => {
    setAmetityDialog(true);
  };

  const handleAmentityDialogClose = () => {
    setAmetityDialog(false);
  };
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const handleLoadMore = () => {
    setIsScrollVisible(true);
  };
  useEffect(() => {
    handleResize();
    checkAvailability();
    window.addEventListener("resize", handleResize); // Check on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTooltipToggle = () => {
    setDamageTooltipOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".tooltip-icon")) {
      setDamageTooltipOpen(false); // Close the first tooltip
    }
    if (!event.target.closest(".confirm-tooltip-icon")) {
      setDamageConfirmTooltipOpen(false); // Close the second tooltip (confirmation dialog)
    }
  };

  // Toggle tooltip for the confirmation price dialog
  const handleConfirmTooltipToggle = () => {
    setDamageConfirmTooltipOpen((prev) => !prev);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (status == "fail") {
      showErrorToast("The Stripe payment is failed.");
      navigate("/rentals/purely_palms/calendar");
    } else if (status == "success") {
      showSuccessToast("The  payment is success.");
      validatePurelyPalmTransaction();
    }
  }, [status]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowFullSizeImage(true);
  };

  const closeFullSizeModal = () => {
    setShowFullSizeImage(false);
  };

  const handleSwipe = (direction) => {
    if (direction === "left") handleNextImage();
    else if (direction === "right") handlePrevImage();
  };

  const handleReviewInputChange = (e) => {
    setReview({ ...review, [e.target.name]: e.target.value });
  };

  const handlePrevImage = () => {
    setAnimationClass("slide-out-left"); // Add exit animation
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
      setAnimationClass("slide-in-right"); // Add enter animation
    }, 300); // Wait for the exit animation to finish
  };

  const handleNextImage = () => {
    setAnimationClass("slide-out-right"); // Add exit animation
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setAnimationClass("slide-in-left"); // Add enter animation
    }, 300); // Wait for the exit animation to finish
  };

  const handleRatingChange = (event, newValue) => {
    setReview({ ...review, rating: newValue });
  };

  const validatePurelyPalmTransaction = async () => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const session_id = queryParams.get("session_id");
    const email = queryParams.get("email");
    try {
      const postResponse = await axiosApiInstance.post(
        "/verify_purely_stripe",
        {
          session_id: session_id,
          email: email,
        }
      );
      if (postResponse.data.status == "error") {
        return;
      }
      setTimeout(() => {
        navigate("/rentals/purely_palms/calendar");
      }, 3000);
    } catch (e) {}
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1280); // Set to true for mobile views (768px and below)
  };
  const checkAvailability = async () => {
    setLoading(true);
    try {
      const response = await axiosApiInstance.post("/get-unavailable-dates", {
        rental,
      });
      const unavailableRanges = response.data.unavailableDates;

      setReviews(response.data.reviews);
      setPricesByDate(response.data.pricesByDate);
      setMonthlyPrices(response.data.monthlyRates);

      // Process unavailable ranges and convert the 'start' and 'end' into Date objects in EST
      const unavailableDates = unavailableRanges.flatMap((range) => {
        const startDate = moment(range.start).toDate(); // No timezone conversion, let moment handle local
        const endDate = moment(range.end).toDate();

        // Disable the end date since users leave on this date, subtract one day
        endDate.setDate(endDate.getDate() - 1);

        // If the start and end are the same day, consider the full day as unavailable
        if (startDate.getTime() === endDate.getTime()) {
          return [startDate];
        } else {
          // Return all dates in the range as unavailable
          const datesInRange = [];
          let currentDate = new Date(startDate);
          while (currentDate <= endDate) {
            datesInRange.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
          }
          return datesInRange;
        }
      });

      setUnavailableDates(unavailableDates);
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
    } finally {
      setLoading(false);
    }
  };
  const CarouselComponent = React.memo(() => (
    <Grid container spacing={2} item xs={12} md={12}>
      <Grid item xs={12}>
        <Slider
          dots
          infinite
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay
        >
          {images.map((src, index) => (
            <div key={index}>
              <img
                src={src}
                alt={`Carousel image ${index}`}
                style={{ maxHeight: "275px" }}
                className="w-full h-auto object-cover cursor-pointer hover:opacity-80 transition-opacity"
                onClick={() => handleImageClick(index)} // Handle image click in the carousel
              />
            </div>
          ))}
        </Slider>
      </Grid>
    </Grid>
  ));
  const handleRangeChange = (item) => {
    const { startDate, endDate } = item.selection;
    setRange([item.selection]);
    const totalPrice = calculateTotalPrice(startDate, endDate);
    setTotalPrice(totalPrice);
  };
  const clearDates = () => {
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStartDate(new Date());
    setEndDate(new Date());
  };
  const handleReserveClick = () => {
    openContactDialog();
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const formatPrice = (price) => {
    return price
      ? price.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: price % 1 === 0 ? 0 : 2, // No decimals for whole numbers
          maximumFractionDigits: 2, // Ensure decimals for fractional prices
        })
      : 0;
  };
  const validatePhone = (phone) => {
    const re = /^[0-9]{10,15}$/; // Adjust the regex based on your phone format requirements
    return re.test(phone);
  };
  const showSuccessToast = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000, // Auto close after 5 seconds
      closeOnClick: true, // Close when clicked
      pauseOnHover: true, // Pause auto close when hovered
      draggable: true, // Enable draggable toast
      hideProgressBar: false, // Show progress bar
    });
  };
  // Error Toast
  const showErrorToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000, // Auto close after 5 seconds
      closeOnClick: true, // Close when clicked
      pauseOnHover: true, // Pause auto close when hovered
      draggable: true, // Enable draggable toast
      hideProgressBar: false, // Show progress bar
    });
  };

  const validDateData = () => {
    if (!range[0].startDate || !range[0].endDate) {
      showErrorToast("Please select a valid check-in and check-out date.");
      return false;
    }

    if (!email) {
      showErrorToast("Please enter your email address.");
      return false;
    }

    if (!validateEmail(email)) {
      showErrorToast("Please enter a valid email address.");
      return false;
    }

    if (!phone) {
      showErrorToast("Please enter your phone number.");
      return false;
    }

    if (!validatePhone(phone)) {
      showErrorToast("Please enter a valid phone number.");
      return false;
    }

    if (!firstname) {
      showErrorToast("Please enter your first name.");
      return false;
    }

    if (!lastname) {
      showErrorToast("Please enter your last name.");
      return false;
    }

    const { startDate, endDate } = range[0];
    const daysDiff = (endDate - startDate) / (1000 * 60 * 60 * 24); // Convert ms to days

    if (daysDiff < 3) {
      showErrorToast("Minimum selection is 3 nights");
      clearDates();
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validDateData()) return;
    const totalPrice = calculateTotalPrice(startDate, endDate);
    setTotalPrice(totalPrice);

    setLoading(true);

    const bookingData = {
      email: email,
      phone: phone,
      startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      guests: guests,
      rental: rental,
      firstname,
      lastname,
    };

    try {
      const response = await axiosApiInstance.post("/book-purely", bookingData);

      if (response.status === 200) {
        showSuccessToast("Request Sent!");
        toggleBookingDialog();
        setTimeout(() => {
          location.reload(); // Optionally refresh the page or redirect
        }, 3000);
      } else {
        showErrorToast("Failed to submit booking. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting booking:", error);
      showErrorToast(
        "Failed to submit booking. Please check your internet connection."
      );
    } finally {
      setLoading(false); // Hide loading
    }
  };

  const handleTogglePriceDetails = () => {
    setShowPriceHomeDetails(!showPriceHomeDetails);
    setShowTooltip(false); // Close tooltip when toggling price details
  };

  const normalizeDate = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
    return newDate;
  };

  const calculateTotalPrice = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
  
    let totalNightPrice = 0;
    let currentDate = new Date(startDate);
    const nights = Math.floor(
      (normalizeDate(new Date(endDate)) - normalizeDate(new Date(startDate))) / (1000 * 60 * 60 * 24)
    );
  
    // Check if the booking is a full month
    const isFullMonthBooking = currentDate.getDate() === 1 && endDate.getDate() === new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const monthName = moment(startDate).format("MMMM");
    const year = startDate.getFullYear();
    const monthlyRate = monthlyPrices.find((price) => price.year === year && price.month === monthName)?.rate;
    console.log("monthlyRate", monthlyPrices, monthName, monthlyRate)
    // Apply monthly rate if full month is booked and monthly rate is available
    if (isFullMonthBooking && monthlyRate) {
      totalNightPrice = monthlyRate;
    } else {
      // Calculate using daily prices
      while (currentDate < new Date(endDate)) {
        const month = moment(currentDate).format("MMMM");
        const year = currentDate.getFullYear();
        const dayIndex = moment(currentDate).date();
  
        const monthPrices = pricesByDate.find((priceData) => priceData.year === year && priceData.month === month);
        const dayPrice = monthPrices ? monthPrices.prices[dayIndex - 1] : 0;
        totalNightPrice += dayPrice;
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
  
    // Fees and taxes
    const cleaningFee = 160;
    const damageWaiverFee = 65;
    const totalBeforeTax = totalNightPrice + cleaningFee + damageWaiverFee;
    const taxRate = 0.12;
    const taxAmount = totalBeforeTax * taxRate;
    const stripeFee = totalBeforeTax * 0.029 + 0.3;
    const finalTotalPrice = totalBeforeTax + taxAmount + stripeFee;
  
    return {
      totalNightPrice,
      nights,
      cleaningFee,
      damageWaiverFee,
      taxAmount,
      finalTotalPrice,
      stripeFee,
      totalBeforeTax,
    };
  };
  

  const LoadingSpinner = () => (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50 z-[100]">
      <div className="flex flex-col items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        <p className="text-white mt-4 text-lg">Please wait...</p>
      </div>
    </div>
  );
  const handleTouchMove = (event) => {
    event.preventDefault();
  };
  const handleShowConfirmationDialog = () => {
    const { startDate, endDate } = range[0];
    const daysDiff = (endDate - startDate) / (1000 * 60 * 60 * 24); // Convert ms to days

    if (daysDiff < 3) {
      showErrorToast("Minimum selection is 3 nights");
      return;
    }
    setPaymentOption("full");
    setShowBookingForm(true);
  };
  const handlePayment = async () => {
    if (!validDateData()) return;
    const totalPrice = calculateTotalPrice(startDate, endDate);
    const today = new Date();
    const checkinDate = new Date(range[0].startDate);
    const daysDifference = (checkinDate - today) / (1000 * 60 * 60 * 24);
    let finalPrice = totalPrice.finalTotalPrice;
    if (paymentOption === "half" && daysDifference >= 30) {
      finalPrice = finalPrice / 2;
    }
    // setTotalPrice(totalPrice);
    setTotalPrice({ ...totalPrice, finalTotalPrice: finalPrice });

    setLoading(true);

    const bookingData = {
      email: email,
      phone: phone,
      startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      guests: guests,
      rental: rental,
      firstname,
      lastname,
      amount: finalPrice,
      paymentOption,
    };

    try {
      const response = await axiosApiInstance.post(
        "/create_checkout_purely_palm",
        bookingData
      );

      const stripe = await stripePromise;
      console.log("response.data.id", response.data.id);
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });

      if (error) {
        console.error("Stripe error:", error);
        alert("There was an issue with the payment.");
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      showErrorToast(e.response.data.message);
      setLoading(false);
    }
  };
  const handleReviewSubmit = async () => {
    if (
      !review.firstName ||
      !review.lastName ||
      !review.email ||
      !review.checkinDate ||
      !review.reviewText
    ) {
      showErrorToast("Please fill out all the fields before submitting.");
      return;
    }

    try {
      const response = await axiosApiInstance.post("/add_review", {
        firstName: review.firstName,
        lastName: review.lastName,
        email: review.email,
        checkInDate: review.checkinDate,
        rating: review.rating,
        reviewText: review.reviewText,
      });
      console.log(response);
      if (response.status === 200) {
        showSuccessToast(response.data.message);
        setOpenDialog(false); // Close dialog on success
      } else {
        showErrorToast("Failed to submit review. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      showErrorToast(error.response.data.message);
    }
  };
  const getTimeSince = (date) => {
    const now = new Date();
    const reviewDate = new Date(date);

    // Normalize both dates to midnight to only compare the days, not the time
    const nowDateOnly = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const reviewDateOnly = new Date(
      reviewDate.getFullYear(),
      reviewDate.getMonth(),
      reviewDate.getDate()
    );

    const timeDiff = nowDateOnly - reviewDateOnly; // Difference in milliseconds
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (days === 0) {
      return "Today";
    } else if (days === 1) {
      return "Yesterday";
    } else if (days < 7) {
      return `${days} day${days > 1 ? "s" : ""} ago`; // Correct singular/plural for days
    } else if (days < 30) {
      const weeks = Math.floor(days / 7);
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`; // Correct singular/plural for weeks
    } else {
      const months = Math.floor(days / 30);
      return `${months} month${months > 1 ? "s" : ""} ago`; // Correct singular/plural for months
    }
  };
  return (
    <div className="container mx-auto max-w-screen-xl my-16 scrollbar-custom ">
      {loading && <LoadingSpinner />}
      <ToastContainer />
      <h1 className="text-2xl font-bold my-8">
        <ArrowBackIosNewIcon
          onClick={() => navigate("/rentals/purely_palms")}
          className="mr-8 cursor-pointer"
        />
        2 min walk to beach, ground floor 1/1 condo w/pool
      </h1>

      {isMobile ? (
        <div>
          <CarouselComponent images={images} />
        </div>
      ) : (
        <div className="hidden lg:grid grid-cols-2 gap-2">
          <div className="col-span-1">
            <img
              src="/img/purely_background.png"
              alt="Beach View"
              className="w-full h-auto object-cover rounded-l-2xl"
            />
          </div>

          <div className="relative grid grid-cols-2 grid-rows-2 gap-2">
            <img
              src="/img/K0.jpg"
              alt="Living Room"
              className="w-full h-full object-cover"
            />
            <img
              src="/img/K2.jpg"
              alt="Dining Room"
              className="w-full h-full object-cover"
            />
            <img
              src="/img/K3.jpg"
              alt="Bedroom"
              className="w-full h-full object-cover rounded-br-2xl"
            />
            <img
              src="/img/K6.jpg"
              alt="Bedroom"
              className="w-full h-full object-cover rounded-br-2xl"
            />
            <button
              className="px-3 py-1 bg-white border-1 border-black text-black rounded-md hover:bg-gray-600 absolute bottom-3 right-5"
              onClick={toggleModal}
            >
              Show all photos
            </button>
          </div>
        </div>
      )}

      {showModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={toggleModal} // Close modal when clicking outside the content area
        >
          <div
            className="modal-content bg-white p-8 rounded-lg relative max-w-4xl w-full max-h-screen overflow-y-auto"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside the content area
          >
            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
              onClick={toggleModal} // Close the modal
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {images.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Image ${index + 1}`}
                  className="w-full h-auto object-cover cursor-pointer hover:opacity-80 transition-opacity"
                  onClick={() => handleImageClick(index)} // Handle image click to show in full-size modal
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="my-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Information Section */}
          <div className={`${isMobile ? "mb-8" : "lg:col-span-2"}`}>
            <h2 className="text-2xl font-bold">
              Entire condo in Miramar Beach, Florida
            </h2>
            <p>4 guests · 1 bedroom · 2 beds · 1 bath</p>
            <Typography className="mt-4">
              Experience the ultimate in beach living at Purely Palms @ Miramar
              Beach! Our fully updated 1-bedroom, 1-bath condo offers a clean,
              modern, and relaxing atmosphere, just a short walk from the
              stunning white sands of the Emerald Coast. The condo includes a
              laundry room with a full-sized washer and dryer for your
              convenience.
            </Typography>
            <Typography>
              This centrally located, ground-floor unit provides easy,
              hassle-free access to your reserved parking spot right outside the
              front door, with plenty of additional parking available within the
              complex.
            </Typography>
            <Typography>Snowbirds are welcome!</Typography>
            {isMobile && (
              <div className="relative">
                <Divider sx={{ my: 2, backgroundColor: "#000" }} />
                <div className="lg:sticky top-16">
                  <div className="bg-white">
                    <div className="flex items-center justify-between">
                      <p className="text-xl font-semibold"></p>
                      <p className="line-through text-gray-500"></p>
                    </div>
                    <div className="mt-4">
                      <p className="font-semibold my-4">
                        Click on your desired check in date then click on check
                        out date. Three night minimum.
                      </p>
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={toggleDatePicker}
                      >
                        <div>
                          <p className="text-gray-500 text-xs font-semibold">
                            Check-in
                          </p>
                          <p>
                            {range[0].startDate
                              ? range[0].startDate.toLocaleDateString()
                              : "Add date"}
                          </p>
                        </div>
                        <div>
                          <p className="text-gray-500 text-xs font-semibold">
                            Check-out
                          </p>
                          <p>
                            {range[0].endDate
                              ? range[0].endDate.toLocaleDateString()
                              : "Add date"}
                          </p>
                        </div>
                      </div>

                      <div className="relative">
                        <div
                          onTouchMove={handleTouchMove} // Prevent page scrolling while interacting with the calendar
                          className="calendar-container"
                        >
                          <DateRange
                            onChange={handleRangeChange}
                            ranges={range}
                            minDate={new Date()}
                            maxDate={
                              new Date(new Date().getFullYear() + 1, 11, 31)
                            }
                            months={1}
                            direction="horizontal"
                            showDateDisplay={false}
                            disabledDates={unavailableDates}
                            moveRangeOnFirstSelection={false}
                            preventSnapRefocus={true}
                            showMonthAndYearPickers={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-6 bg-gray-50 p-4 rounded-md shadow-md w-full">
                      <div className="flex justify-between items-center">
                        <p>Guests</p>
                        <select
                          value={guests}
                          onChange={(e) => setGuests(e.target.value)}
                          className="border p-2 rounded-md w-full ml-4"
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                        </select>
                      </div>
                      <div className="flex justify-between mt-4">
                        <p>{`Nights`}</p>
                        <p>{totalPrice?.nights} </p>
                      </div>
                      <div className="flex justify-between font-bold mt-4">
                        <p onClick={handleTogglePriceDetails}>
                          Total
                          <span className="underline cursor-pointer ml-2">
                            Price Details
                          </span>
                        </p>
                        <p className="font-semibold">
                          ${totalPrice?.totalBeforeTax}
                        </p>
                      </div>
                    </div>

                    <div
                      className={`overflow-hidden transition-all duration-500 ease-in-out ${
                        showPriceHomeDetails
                          ? "max-h-full opacity-100"
                          : "max-h-0 opacity-0"
                      }`}
                    >
                      {showPriceHomeDetails && (
                        <div className="mt-4 bg-gray-50 p-4 rounded-md shadow-md space-y-4">
                          {/* Additional Pricing Details */}
                          <div className="flex justify-between text-sm">
                            <span className="font-semibold">Check-in:</span>
                            <span>
                              {range[0].startDate?.toLocaleDateString()}
                            </span>
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="font-semibold">Check-out:</span>
                            <span>
                              {range[0].endDate?.toLocaleDateString()}
                            </span>
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="font-semibold">Guests:</span>
                            <span>{guests}</span>
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="font-semibold">Total Nights:</span>
                            <span>{totalPrice?.nights}</span>
                          </div>
                          <div className="flex justify-between text-sm">
                            <span className="font-semibold">Total:</span>
                            <span>{totalPrice?.totalNightPrice}</span>
                          </div>
                          <hr className="border-t border-gray-200 my-2" />
                          <div className="flex justify-between text-sm">
                            <span className="font-semibold">Cleaning Fee:</span>
                            <span>{formatPrice(totalPrice?.cleaningFee)}</span>
                          </div>
                          <div className="relative flex justify-between text-sm">
                            <span className="font-semibold flex items-center">
                              Damage Waiver:
                              <Tooltip
                                title="This is an insurance policy that covers minor, accidental damage to the rental property during your stay, allowing you to book with peace of mind knowing that small mishaps are protected, so you won't be held responsible for the full cost of repairs, provided the damage is not intentional or due to gross negligence; essentially acting as a safety net for unexpected incidents."
                                open={damageTooltipOpen}
                                onClose={handleTooltipClose}
                                disableHoverListener // Disable hover for mobile
                                disableFocusListener
                                disableTouchListener
                                onClick={handleTooltipOpen} // Show tooltip on click/tap
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, -30], // Adjust this value to move the tooltip closer
                                      },
                                    },
                                  ],
                                }}
                              >
                                <IconButton className="m-0">
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </span>
                            <span>
                              {formatPrice(totalPrice?.damageWaiverFee)}
                            </span>
                          </div>

                          <hr className="border-t border-gray-200 my-2" />
                          <div className="flex justify-between text-lg font-bold">
                            <span>Total With Fees:</span>
                            <span>
                              {formatPrice(totalPrice?.totalBeforeTax)}
                            </span>
                          </div>
                          <div
                            className="flex justify-end cursor-pointer mt-4 text-gray-500"
                            onClick={handleTogglePriceDetails} // Use the same handler to close
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                            <span className="ml-2">Close</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <button
                      className="w-full mt-6 bg-pink-500 text-white py-3 px-4 rounded-lg hover:bg-pink-600"
                      onClick={handleShowConfirmationDialog}
                    >
                      Reserve
                    </button>
                    <div className="mt-4 text-left">
                      <p className="text-gray-600">
                        If you have any issues or questions, please contact us
                        at{" "}
                        <a
                          href="mailto:purelypalms@gmail.com"
                          className="text-blue-600 underline"
                        >
                          purelypalms@gmail.com
                        </a>
                        . Please enjoy your stay!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Box sx={{ my: 4 }}>
              <Link
                href="https://www.instagram.com/purelypalms_miramarbeach?igsh=c2F3OHI2dzA3Zmhp"
                sx={{
                  textDecoration: "none",
                  "&:hover": { textDecoration: "none" },
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                  Social Media - Instagram
                </Typography>
              </Link>
            </Box>

            <Box sx={{ my: 4 }}>
              <Link
                href="https://old98destin.com/beach-cams/pompano-joes-miramar-beach/"
                sx={{
                  textDecoration: "none",
                  "&:hover": { textDecoration: "none" },
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                  Live Miramar Beach Webcam!
                </Typography>
                <p>
                  LIVE view of Miramar Beach from Pompano Joe's, only a 2 min
                  walk from the condo!
                </p>
              </Link>
            </Box>

            <Box sx={{ my: 4 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                Property Rules
              </Typography>
              <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                Checking In and Out
              </Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <AccessTimeIcon className="mr-2" />
                <Typography>Check-in after 4:00 PM Central</Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <AccessTimeIcon className="mr-2" />
                <Typography>Checkout before 10:00 AM Central</Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                <LockIcon className="mr-2" />
                <Typography>Self check-in with smart lock</Typography>
              </Box>

              {/* During your stay */}
              <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                During Your Stay
              </Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <PeopleIcon className="mr-2" />
                <Typography>4 guests maximum</Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <PetsIcon className="mr-2" />
                <Typography>No pets</Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <AccessTimeIcon className="mr-2" />
                <Typography>Quiet hours: 10:00 PM - 7:00 AM Central</Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                <EventIcon className="mr-2" />
                <Typography>No parties or events</Typography>
              </Box>

              {/* Additional rules */}
              <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                Additional Rules
              </Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <SmokeFreeIcon className="mr-2" />
                <Typography>No smoking</Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <NoPhotographyIcon className="mr-2" />
                <Typography>No commercial photography</Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 4, backgroundColor: "#000" }} />
            <Box sx={{ my: 4 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                What this place offers
              </Typography>

              {/* Amenities Grid */}
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" }, // 1 column on xs (mobile), 2 columns on sm (tablet) and larger
                  gap: 2,
                  mb: 2,
                }}
              >
                <Box display="flex" alignItems="center">
                  <BeachAccessIcon sx={{ mr: 1 }} />
                  <Typography>Shared beach access</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <KitchenIcon sx={{ mr: 1 }} />
                  <Typography>Patio</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <WifiIcon sx={{ mr: 1 }} />
                  <Typography>Wifi</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <WorkIcon sx={{ mr: 1 }} />
                  <Typography>Dedicated workspace</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <LocalParkingIcon sx={{ mr: 1 }} />
                  <Typography>Free parking on premises</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <PoolIcon sx={{ mr: 1 }} />
                  <Typography>Shared outdoor pool</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <TvIcon sx={{ mr: 1 }} />
                  <Typography>TV</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <LocalLaundryServiceIcon sx={{ mr: 1 }} />
                  <Typography>Washer – In unit</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <CameraAltIcon sx={{ mr: 1 }} />
                  <Typography>Exterior security cameras on property</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  
                <LocalLaundryServiceIcon sx={{ mr: 1 }} />
                  <Typography>Dryer - In unit</Typography>
                </Box>
              </Box>

              {/* Show All Button */}
              <Button
                variant="outlined"
                sx={{ borderRadius: 2, textTransform: "none" }}
                onClick={handleAmentityDialogOpen}
              >
                Show all 37 amenities
              </Button>

              <AmenitiesDialog
                open={openAmetityDialog}
                handleClose={handleAmentityDialogClose}
              />
            </Box>
            <Divider sx={{ my: 4, backgroundColor: "#000" }} />
            <Box sx={{ my: 4 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                Payment
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography>
                  If your check-in date is more than 30 days away, you have the
                  option to either pay 50% as a deposit or the full amount now.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ my: 4 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                Refund Policy
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography>
                  You can get a full refund if requested 30 or more days before
                  your check-in date.
                  <Link
                    href="/rentals/purely_palms/refund"
                    className="text-blue-600 underline mx-1"
                  >
                    Click this link
                  </Link>
                  to request a refund.
                </Typography>
              </Box>
            </Box>
          </div>

          {/* Right Reservation Section */}
          {!isMobile && (
            <div className="relative">
              <div className="lg:sticky top-16">
                <div className="border rounded-3xl p-4 shadow-lg bg-white">
                  <div className="flex items-center justify-between">
                    <p className="text-xl font-semibold"></p>
                    <p className="line-through text-gray-500"></p>
                  </div>
                  <div className="mt-4">
                    <p className="font-semibold my-4">
                      Click on your desired check in date then click on check
                      out date. Three night minimum.
                    </p>
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={toggleDatePicker}
                    >
                      <div>
                        <p className="text-gray-500 text-xs font-semibold">
                          Check-in
                        </p>
                        <p>
                          {range[0].startDate
                            ? range[0].startDate.toLocaleDateString()
                            : "Add date"}
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-500 text-xs font-semibold">
                          Check-out
                        </p>
                        <p>
                          {range[0].endDate
                            ? range[0].endDate.toLocaleDateString()
                            : "Add date"}
                        </p>
                      </div>
                    </div>

                    <div className="relative">
                      <div
                        onTouchMove={handleTouchMove} // Prevent page scrolling while interacting with the calendar
                        className="calendar-container"
                      >
                        <DateRange
                          onChange={handleRangeChange}
                          ranges={range}
                          minDate={new Date()}
                          maxDate={
                            new Date(new Date().getFullYear() + 1, 11, 31)
                          }
                          months={1}
                          direction="horizontal"
                          showDateDisplay={false}
                          disabledDates={unavailableDates}
                          moveRangeOnFirstSelection={false}
                          preventSnapRefocus={true}
                          showMonthAndYearPickers={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 bg-gray-50 p-4 rounded-md shadow-md w-full select-none">
                    <div className="flex justify-between items-center">
                      <p>Guests</p>
                      <select
                        value={guests}
                        onChange={(e) => setGuests(e.target.value)}
                        className="border p-2 rounded-md w-full ml-4"
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                      </select>
                    </div>
                    <div className="flex justify-between mt-4">
                      <p>{`Nights`}</p>
                      <p>{totalPrice?.nights} </p>
                      {/* <p>{`${formatPrice(totalPrice?.totalNightPrice)}`}</p> */}
                    </div>
                    <div className="flex justify-between font-bold mt-4">
                      <p onClick={handleTogglePriceDetails}>
                        Total
                        <span className="underline cursor-pointer ml-2">
                          Price Details
                        </span>
                      </p>
                      <p className="font-semibold">
                        ${totalPrice?.totalBeforeTax}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`overflow-hidden transition-all duration-500 ease-in-out ${
                      showPriceHomeDetails
                        ? "max-h-full opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    {showPriceHomeDetails && (
                      <div className="mt-4 bg-gray-50 p-4 rounded-md shadow-md space-y-4">
                        <div className="flex justify-between text-sm">
                          <span className="font-semibold">Check-in:</span>
                          <span>
                            {range[0].startDate?.toLocaleDateString()}
                          </span>
                        </div>
                        <div className="flex justify-between text-sm">
                          <span className="font-semibold">Check-out:</span>
                          <span>{range[0].endDate?.toLocaleDateString()}</span>
                        </div>
                        <div className="flex justify-between text-sm">
                          <span className="font-semibold">Guests:</span>
                          <span>{guests}</span>
                        </div>
                        <div className="flex justify-between text-sm">
                          <span className="font-semibold">Total Nights:</span>
                          <span>{totalPrice?.nights}</span>
                        </div>
                        <div className="flex justify-between text-sm">
                          <span className="font-semibold">Total:</span>
                          <span>{totalPrice?.totalNightPrice}</span>
                        </div>

                        <hr className="border-t border-gray-200 my-2" />

                        <div className="flex justify-between text-sm">
                          <span className="font-semibold">Cleaning Fee:</span>
                          <span>{formatPrice(totalPrice?.cleaningFee)}</span>
                        </div>
                        <div className="relative flex justify-between text-sm">
                          <span className="font-semibold flex items-center">
                            Damage Waiver:
                            <Tooltip
                              title="This is an insurance policy that covers minor, accidental damage to the rental property during your stay, allowing you to book with peace of mind knowing that small mishaps are protected, so you won't be held responsible for the full cost of repairs, provided the damage is not intentional or due to gross negligence; essentially acting as a safety net for unexpected incidents."
                              open={damageTooltipOpen}
                              onClose={handleTooltipClose}
                              disableHoverListener // Disable hover for mobile
                              disableFocusListener
                              disableTouchListener
                              onClick={handleTooltipOpen} // Show tooltip on click/tap
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, -20], // Adjust this value to move the tooltip closer
                                    },
                                  },
                                ],
                              }}
                            >
                              <IconButton className="m-0">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                          <span>
                            {formatPrice(totalPrice?.damageWaiverFee)}
                          </span>
                        </div>

                        <hr className="border-t border-gray-200 my-2" />

                        <div className="flex justify-between text-lg font-bold">
                          <span>Total With Fees:</span>
                          <span>{formatPrice(totalPrice?.totalBeforeTax)}</span>
                        </div>

                        <div
                          className="flex justify-end cursor-pointer mt-4 text-gray-500"
                          onClick={handleTogglePriceDetails} // Use the same handler to close
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          <span className="ml-2">Close</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <button
                    className="w-full mt-6 bg-pink-500 text-white py-3 px-4 rounded-lg hover:bg-pink-600"
                    onClick={handleShowConfirmationDialog}
                  >
                    Reserve
                  </button>

                  <div className="mt-4 text-left">
                    <p className="text-gray-600">
                      If you have any issues or questions, please contact us at{" "}
                      <a
                        href="mailto:purelypalms@gmail.com"
                        className="text-blue-600 underline"
                      >
                        purelypalms@gmail.com
                      </a>
                      . Please enjoy your stay!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="my-16">
          <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mr: 1 }}>
              Reviews
            </Typography>
            {averageRating && (
              <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                {/* Rating Bar */}
                <Rating
                  value={parseFloat(averageRating)}
                  precision={0.1}
                  readOnly
                />
                {/* <Typography variant="h5" sx={{ ml: 1 }}>
                  ({reviews.length} review{reviews.length > 1 ? "s" : ""})
                </Typography> */}
              </Box>
            )}
            <IconButton
              onClick={handleDialogOpen}
              sx={{
                backgroundColor: "#2563eb", // Blue background
                color: "#fff", // White icon color
                borderRadius: "50%", // Round background
                padding: "8px", // Padding to make the icon larger
                "&:hover": {
                  backgroundColor: "#1d4ed8", // Darker blue on hover
                  color: "#fff",
                },
                ml: 2,
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
          <div>
            {reviews.length === 0 ? (
              <Typography>No reviews yet</Typography>
            ) : (
              <div>
                {/* Show first 5 reviews with no scrollbar */}
                {!isScrollVisible ? (
                  <div>
                    {reviews.slice(0, 5).map((review) => (
                      <div
                        key={review.id}
                        className="review-item mb-4 p-4 border rounded-md shadow-sm flex"
                      >
                        <div>
                          <div className="flex items-center">
                            <Typography variant="h6" className="font-bold">
                              {review.last_name}
                            </Typography>
                            <Rating value={review.rating} readOnly />
                            <Typography
                              variant="body2"
                              className="ml-2 text-gray-500"
                            >
                              {getTimeSince(review.created_at)}
                            </Typography>
                          </div>
                          <Typography variant="body1" className="mt-2">
                            {review.review}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="reviews-scroll scrollbar-custom"
                    style={{
                      maxHeight: "500px",
                      overflowY: "auto",
                      paddingRight: "10px",
                    }}
                  >
                    {reviews.map((review) => (
                      <div
                        key={review.id}
                        className="review-item mb-4 p-4 border rounded-md shadow-sm flex"
                      >
                        <div>
                          <div className="flex items-center">
                            <Typography variant="h6" className="font-bold">
                              {review.last_name}
                            </Typography>
                            <Rating value={review.rating} readOnly />
                            <Typography
                              variant="body2"
                              className="ml-2 text-gray-500"
                            >
                              {getTimeSince(review.created_at)}
                            </Typography>
                          </div>
                          <Typography variant="body1" className="mt-2">
                            {review.review}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Load More Button on the Right */}
          {!isScrollVisible && reviews.length > 5 && (
            <div className="flex justify-end mt-4">
              <button
                onClick={handleLoadMore}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Load More
              </button>
            </div>
          )}
          {/* Section 2: Location (Where you'll be) */}
          <div className="my-8">
            <hr className="my-16" />
            <h3 className="text-xl font-bold my-8">Where you'll be</h3>
            <p className="text-gray-500 my-4">
              Miramar Beach, Florida, United States
            </p>

            {/* Google Maps Integration */}
            <div className="h-96 mt-4">
              <LoadScript googleMapsApiKey="AIzaSyD-XgMXEcxxhOGZ1nNhxtDTGLj9UeVrV4c">
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  center={{ lat: 30.38052526097164, lng: -86.38636708602937 }}
                  zoom={12}
                >
                  <Marker
                    position={{
                      lat: 30.38052526097164,
                      lng: -86.38636708602937,
                    }}
                  />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>

        {showBookingForm && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto"
            onClick={toggleBookingDialog}
          >
            <div
              className="bg-white p-8 rounded-lg max-w-2xl w-full relative overflow-y-auto  scrollbar-custom"
              style={{ maxHeight: "100vh" }}
              onClick={(e) => e.stopPropagation()}
            >
              {!isMobile && (
                <button
                  className="absolute top-4 right-6 text-gray-600 hover:text-gray-900"
                  onClick={() => setShowBookingForm(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              )}

              <h2 className="text-xl font-bold mb-4">
                {isMobile && (
                  <ArrowBackIosNewIcon
                    onClick={() => setShowBookingForm(false)}
                    className="mr-1 cursor-pointer"
                    style={{ marginLeft: "-30px" }}
                  />
                )}
                Confirm your Booking
              </h2>

              <div className="mb-4">
                <span
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </span>
                <input
                  type="text"
                  id="firstname"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <span
                  htmlFor="lastname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </span>
                <input
                  type="text"
                  id="lastname"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full"
                  required
                />
              </div>

              {/* Contact information form */}
              <div className="mb-4">
                <span
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </span>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full"
                  required
                />
              </div>

              <div className="mb-4">
                <span
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone
                </span>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="mt-1 p-2 border rounded-md w-full"
                  required
                />
              </div>

              {/* Total Price Display */}
              <div className="mb-4">
                <div className="flex justify-between items-center">
                  <div className="font-bold text-2xl">
                    Total:
                    {paymentOption === "half" && daysDifference >= 30
                      ? formatPrice(totalPrice.finalTotalPrice / 2)
                      : formatPrice(totalPrice.finalTotalPrice)}
                  </div>
                  <div
                    className="cursor-pointer text-blue-600 underline flex items-center space-x-2"
                    onClick={() => setShowPriceDetails(!showPriceDetails)}
                  >
                    <span>Price details</span>
                    <svg
                      className={`w-5 h-5 transform transition-transform duration-300 ${
                        showPriceDetails ? "rotate-180" : "rotate-0"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div
                className={`overflow-hidden transition-all my-4 duration-500 ease-in-out ${
                  showPriceDetails
                    ? "max-h-full opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                {showPriceDetails && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-md shadow-md space-y-4 select-none">
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Check-in:</span>
                      <span>{range[0].startDate?.toLocaleDateString()}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Check-out:</span>
                      <span>{range[0].endDate?.toLocaleDateString()}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Guests:</span>
                      <span>{guests}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Total Nights:</span>
                      <span>{totalPrice.nights}</span>
                    </div>
                    {/* <div className="flex justify-between text-sm">
                      <span className="font-semibold">Total Nightly Rate:</span>
                      <span>{formatPrice(totalPrice.totalNightPrice)}</span>
                    </div> */}

                    <hr className="border-t border-gray-200 my-2" />

                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Total:</span>
                      <span>{formatPrice(totalPrice.totalNightPrice)}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Cleaning Fee:</span>
                      <span>{formatPrice(totalPrice.cleaningFee)}</span>
                    </div>
                    <div className="relative flex justify-between text-sm">
                      <span className="font-semibold flex items-center">
                        Damage Waiver:
                        <Tooltip
                          title="This is an insurance policy that covers minor, accidental damage to the rental property during your stay, allowing you to book with peace of mind knowing that small mishaps are protected, so you won't be held responsible for the full cost of repairs, provided the damage is not intentional or due to gross negligence; essentially acting as a safety net for unexpected incidents."
                          open={damageConfirmTooltipOpen}
                          disableHoverListener // Disable hover for mobile
                          disableFocusListener
                          disableTouchListener
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -15], // Adjust this value to move the tooltip closer
                                },
                              },
                            ],
                          }}
                        >
                          <IconButton
                            className="confirm-tooltip-icon m-0"
                            onClick={handleConfirmTooltipToggle}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                      <span>{formatPrice(totalPrice?.damageWaiverFee)}</span>
                    </div>

                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Credit Card Fee:</span>
                      <span>{formatPrice(totalPrice.stripeFee)}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="font-semibold">Tax:</span>
                      <span>{formatPrice(totalPrice?.taxAmount)}</span>
                    </div>

                    <hr className="border-t border-gray-200 my-2" />

                    <div className="flex justify-between text-lg font-bold">
                      <span>Total With Fees and Tax:</span>
                      <span>{formatPrice(totalPrice?.finalTotalPrice)}</span>
                    </div>
                  </div>
                )}
              </div>

              {/* Additional form fields for Last Name, Email, Phone */}
              <div className="mt-4">
                <p className="text-gray-600 mb-2">
                  If your check-in date is more than 30 days away, you have the
                  option to either pay 50% as a deposit or the full amount now.
                  Please choose the amount you'd like to pay.
                </p>

                <label className="flex items-center">
                  <input
                    type="radio"
                    value="full"
                    checked={paymentOption === "full"}
                    onChange={(e) => setPaymentOption(e.target.value)}
                    className="mr-2"
                  />
                  <span className="text-gray-800">Full Payment</span>
                </label>

                <label className="flex items-center mt-2">
                  <input
                    type="radio"
                    value="half"
                    checked={paymentOption === "half"}
                    onChange={(e) => setPaymentOption(e.target.value)}
                    disabled={daysDifference < 30} // Disable if less than 30 days
                    className="mr-2"
                  />
                  <span
                    className={`text-gray-800 ${
                      daysDifference < 30 ? "text-gray-400" : ""
                    }`}
                  >
                    1/2 Payment
                  </span>
                </label>
              </div>
              <div className="flex space-x-4">
                <button
                  className="w-full bg-pink-500 text-white py-2 px-4 rounded-lg hover:bg-pink-600"
                  onClick={handlePayment}
                >
                  Pay Now
                </button>
                <button
                  className="w-full bg-pink-500 text-white py-2 px-4 rounded-lg hover:bg-pink-600"
                  onClick={() => setShowBookingForm(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Your Review</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="First Name"
            name="firstName"
            value={review.firstName}
            onChange={handleReviewInputChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Last Name"
            name="lastName"
            value={review.lastName}
            onChange={handleReviewInputChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={review.email}
            onChange={handleReviewInputChange}
            fullWidth
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Check-In Date"
            name="checkinDate"
            type="date"
            value={review.checkinDate}
            onChange={handleReviewInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <Box sx={{ my: 2 }}>
            <Typography component="legend">Rate your stay</Typography>
            <Rating
              name="rating"
              value={review.rating}
              onChange={handleRatingChange}
            />
          </Box>
          <TextField
            margin="dense"
            label="Review"
            name="reviewText"
            value={review.reviewText}
            onChange={handleReviewInputChange}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleReviewSubmit} color="primary">
            Submit Review
          </Button>
        </DialogActions>
      </Dialog>

      {showFullSizeImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50"
          onClick={closeFullSizeModal} // Close full-size modal when clicking outside
        >
          <div
            className="relative flex items-center justify-center w-full h-full"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside the content area
          >
            {/* Close Button */}
            <button
              className="absolute top-2 left-2 text-white hover:text-gray-300"
              onClick={closeFullSizeModal}
            >
              <span className="text-lg">Close</span>
            </button>

            {/* Left Navigation Arrow */}
            <button
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-60 rounded-full p-2 hover:bg-gray-700 text-white"
              onClick={handlePrevImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>

            {/* Right Navigation Arrow */}
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-60 rounded-full p-2 hover:bg-gray-700 text-white"
              onClick={handleNextImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>

            {/* Full-size Image */}
            <img
              src={images[currentImageIndex]}
              alt={`Full-size Image ${currentImageIndex + 1}`}
              className="max-w-full max-h-full object-cover transition-opacity duration-500"
            />

            {/* Image Counter */}
            <div className="absolute top-2 right-2 text-white text-lg">
              {currentImageIndex + 1} / {images.length}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
