// src/config.js
const config = {
  IS_PROD: false,
  // API_ENDPOINT: "https://api.theqr.store",
  API_ENDPOINT: "https://btq10yv879.execute-api.us-east-1.amazonaws.com/prod",
  // API_ENDPOINT: "https://gyaoklrn18.execute-api.us-east-1.amazonaws.com/ua",
  // API_ENDPOINT: "http://192.168.11.92:4242/api/",
  // API_TOKEN: "BONMzEMXTR8wuMSdoQmpS1B1JRJcgGVW1yxiZLNw",
  STRIPE_SK:
    "pk_live_51OAsF3Ckd4B3x2sGfhZzthinbMVwoy1IyBB3PrYItk8TMs0A4M8AU2YQTeAFlIJZNqKjR5uFhOjFI8F4cKdMOR4q003r56gTh0", // The QR Store
    // "pk_test_51OAsF3Ckd4B3x2sGLipRlI73yLaiF0uaie2pUDhpJALJg4vJY5UJjGlVk0Y8JEdokxfLk2x2LGuNLIvrwxDI53cT00zqWdA8vV", // The QR Store,
  // "pk_test_51NngOXIQDHmAmsomsPeIBQcgrDSm5EcgZdfQaXtl2WmKOVsYwTj0FFlktgUkOYRiT6o1qTjMX4HvrfQqC8KBX4ye00g0P0BDin", // Lewis,
  // "pk_test_51Nzi8HKcDojd1fHaUFnqRcoGz5OF1CByvofUCWWGmJSm0UsZHcjMCRLJE7nIqGGdmzPwU3qouQNyzAonBimFEM2m00lfKjpTCC", // Tony
  // "pk_test_51Q50NfH8YXUr5WgLPzKnr00Hx1XhhwkJdaDVRsQO8ApCKNdfEEB0G9WXDtIjlVSlpktEnyOwUA1iy1gk3sdJSUL200GDD0AtZS", // ai test key
};

export default config;
