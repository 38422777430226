import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Button, Typography, CircularProgress, Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axiosApiInstance from '../../store/axiosApi';

const CancelRefund = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const cancelRequest = async () => {
      try {
        setLoading(true);
        const response = await axiosApiInstance.post(`/cancel_tranaction`, {token});
        setMessage(response.data.message);
      } catch (error) {
        setMessage(error.response ? error.response.data.message : "Error processing refund request.");
      } finally {
        setLoading(false);
      }
    };

    cancelRequest();
  }, [token]);

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <ArrowBackIosNewIcon onClick={() => navigate(-1)} className="cursor-pointer" />
        <Typography variant="h4" sx={{ ml: 1 }}>Cancel Request</Typography>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Typography variant="body1" align="center">{message}</Typography>
      )}
    </Container>
  );
};

export default CancelRefund;
