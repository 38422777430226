import React from "react";
import {
  Container,
  Typography,
  Divider,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PeopleIcon from "@mui/icons-material/People";
import PetsIcon from "@mui/icons-material/Pets";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";

const PropertyRules = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      {/* Back Button and Title */}
      <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
        <IconButton onClick={() => navigate("/rentals/purely_palms")} edge="start">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", ml: 2 }}>
          Property Rules
        </Typography>
      </Box>

      <Divider sx={{ my: 2, backgroundColor: "#000" }} />

      {/* Rules Section */}
      <Box sx={{ my: 4 }}>
        {/* Checking in and out */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Checking In and Out
        </Typography>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <AccessTimeIcon className="mr-2" />
          <Typography>Check-in after 4:00 PM</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <AccessTimeIcon className="mr-2" />
          <Typography>Checkout before 10:00 AM</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
          <LockIcon className="mr-2" />
          <Typography>Self check-in with smart lock</Typography>
        </Box>

        {/* During your stay */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          During Your Stay
        </Typography>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <PeopleIcon className="mr-2" />
          <Typography>4 guests maximum</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <PetsIcon className="mr-2" />
          <Typography>No pets</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <AccessTimeIcon className="mr-2" />
          <Typography>Quiet hours: 10:00 PM - 7:00 AM</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
          <EventIcon className="mr-2" />
          <Typography>No parties or events</Typography>
        </Box>

        {/* Additional rules */}
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Additional Rules
        </Typography>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <SmokeFreeIcon className="mr-2" />
          <Typography>No smoking</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
          <NoPhotographyIcon className="mr-2" />
          <Typography>No commercial photography</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default PropertyRules;
